import { Component } from '@angular/core';

import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UsuarioService } from './services/usuario.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import { environment } from '../environments/environment';
import { FCM } from '@ionic-native/fcm/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  private updateURL = `${environment.apiBase}/storage/update/update.xml`;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private usuarioService: UsuarioService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private appUpdate: AppUpdate,
    private fcm: FCM,
    private alertController: AlertController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is('cordova')) {
        this.appUpdate.checkAppUpdate(this.updateURL).then(() => { console.log('Update available') });
        this.updateToken();
        this.notifications();
      }

      this.redireccionar();
    });
  }


  updateToken() {
    let usuario = this.usuarioService.getUser()
    if (usuario) {
      this.fcm.getToken()
        .then(token => {
          this.usuarioService.updateToken(token)
            .subscribe(
              data => {
                console.log(data);
              },
              err => {
                console.log(err)
              }
            )
        })
    }
  }

  notifications() {
    this.fcm.onNotification()
      .subscribe(data => {
        console.log(data);
        if (data?.wasTapped) {
          switch (data?.tipo) {
            case 'hallazgo':
              this.router.navigate([data?.page, data?.id])
              break;
          }
        } else {
          this.mensajePersonalizado(data)
        }
      });
  }

  redireccionar() {
    this.authenticationService.authState.subscribe(state => {
      if (state) {
        let usuario = this.usuarioService.getUser()
        if (usuario) {
          switch(usuario.tipo_user){
          case 1: this.router.navigate(['/menu/gestion-hallazgo']); break;
          case 2: this.router.navigate(['/menu/gestion-hallazgo']); break;
          case 3: this.router.navigate(['/menu/gestion-siniestro']); break;
          case 4: this.router.navigate(['/menu/gestion-siniestro']); break;
          case 5: this.router.navigate(['/menu/gestion-hallazgo']); break;
          default: this.authenticationService.logout(); break;
          }
        }
      } else {
        this.router.navigate(['/login']);
      }
    })
  }

  async mensajePersonalizado(data: any) {
    let header = '';
    let message = '';
    switch (data.tipo) {
      case 'hallazgo':
        header = '¡Se ha subido un nuevo hallazgo!';
        message = "¿Deseas verlo ahora?";
        break;
    }

    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            this.router.navigate([data?.page, data?.id])
          }
        }
      ]
    });

    await alert.present();
  }
}
