import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardLoginService } from './services/auth-guard-login.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate:[AuthGuardLoginService]
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule),
    canActivate:[AuthGuardService]
  },
  {
    path: 'gestion-usuarios',
    loadChildren: () => import('./pages/gestion-usuarios/gestion-usuarios.module').then( m => m.GestionUsuariosPageModule)
  },
  {
    path: 'modificar-usuario',
    loadChildren: () => import('./pages/modificar-usuario/modificar-usuario.module').then( m => m.ModificarUsuarioPageModule)
  },
  {
    path: 'registrar-usuario',
    loadChildren: () => import('./pages/registrar-usuario/registrar-usuario.module').then( m => m.RegistrarUsuarioPageModule)
  },
  {
    path: 'levantamiento-siniestro',
    loadChildren: () => import('./pages/levantamiento-siniestro/levantamiento-siniestro.module').then( m => m.LevantamientoSiniestroPageModule)
  },
  {
    path: 'gestion-siniestro',
    loadChildren: () => import('./pages/gestion-siniestro/gestion-siniestro.module').then( m => m.GestionSiniestroPageModule)
  },
  {
    path: 'editar-siniestro',
    loadChildren: () => import('./pages/editar-siniestro/editar-siniestro.module').then( m => m.EditarSiniestroPageModule)
  },
  {
    path: 'modificar-vehiculo',
    loadChildren: () => import('./pages/modificar-vehiculo/modificar-vehiculo.module').then( m => m.ModificarVehiculoPageModule)
  },
  {
    path: 'modificar-persona',
    loadChildren: () => import('./pages/modificar-persona/modificar-persona.module').then( m => m.ModificarPersonaPageModule)
  },
  {
    path: 'modificar-seguro',
    loadChildren: () => import('./pages/modificar-seguro/modificar-seguro.module').then( m => m.ModificarSeguroPageModule)
  },
  {
    path: 'editar-analisis-senalamiento',
    loadChildren: () => import('./pages/editar-analisis-senalamiento/editar-analisis-senalamiento.module').then( m => m.EditarAnalisisSenalamientoPageModule)
  },
  {
    path: 'editar-analisis-dispositivo',
    loadChildren: () => import('./pages/editar-analisis-dispositivo/editar-analisis-dispositivo.module').then( m => m.EditarAnalisisDispositivoPageModule)
  },
  {
    path: 'formato-pdf',
    loadChildren: () => import('./pages/formato-pdf/formato-pdf.module').then( m => m.FormatoPDFPageModule)
  },
  {
    path: 'gestion-hallazgos-offline',
    loadChildren: () => import('./pages/gestion-hallazgos-offline/gestion-hallazgos-offline.module').then( m => m.GestionHallazgosOfflinePageModule)
  },
  {
    path: 'edit-danos-infraestructura',
    loadChildren: () => import('./pages/edit-danos-infraestructura/edit-danos-infraestructura.module').then( m => m.EditDanosInfraestructuraPageModule)
  },
  {
    path: 'bitacora-linea',
    loadChildren: () => import('./pages/bitacora-linea/bitacora-linea.module').then( m => m.BitacoraLineaPageModule)
  },


]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
