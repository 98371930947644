import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http:HttpClient) { }

  getUser(){
    let user= JSON.parse(localStorage.getItem('usuario'))

    if(user){
      return user;
    }
    return null;
  }

  getToken(){
    let token = localStorage.getItem('token');

    if(token){
      return token
    }
    return null;
  }
  guardar(user:User){
    return this.http.post(`${environment.apiBase}/api/user`,user)
  }
  actualizarUser(user:User,id:number){
    return this.http.put(`${environment.apiBase}/api/user/${id}`,user)
  }
  eliminarUser(id:number){
    return this.http.delete(`${environment.apiBase}/api/user/${id}`)
  }
  getUsers(){
    return this.http.get(`${environment.apiBase}/api/user`)
    
  }
  getUsersID(id:number){
    return this.http.get(`${environment.apiBase}/api/user/${id}`)
    
  }
  updateToken(token:string){
    return this.http.put(`${environment.apiBase}/api/user/update/token`, {token})
    
  }

}
